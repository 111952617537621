
if ( document.querySelector("#phone") != null) {
    const formPhone = document.querySelector("#phone");

    const SectionContactF = window.intlTelInput(formPhone, {
        nationalMode: true,
        separateDialCode: true,
        utilsScript: "/js/utils.js"
    });

    // Home Contact Form Send
    if ( document.getElementById('homeFormSubmit') != null ) {
        document.getElementById('homeFormSubmit').addEventListener('click', function (e){
            e.preventDefault();

            const this_form = document.getElementById('home_form')

            const BASE_URL = "https://miraclehairturkey.com/api"
            const ROUTE_URL = '/home-contact-request'
            const url = BASE_URL + ROUTE_URL

            const phone_coded = SectionContactF.getNumber(intlTelInputUtils.numberFormat.E164);
            const countryData = SectionContactF.getSelectedCountryData();


            let country = countryData.name
            let date = new Date();
            let formName = this_form.getAttribute("name")
            let referral_page = window.location.href;
            let language = navigator.language;
            let time_zone_offset = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var form = document.querySelector('#home_form');
            var formData = new FormData(form);
            formData.append('phone', phone_coded)
            formData.append('form_name', formName)
            formData.append('country', country)
            formData.append('user_language', language)
            formData.append('user_time_zone', time_zone_offset)
            formData.append('referral_page', referral_page)
            formData.append('date', date)

            let client_form_name = formData.get('name')

            var requestOptions = {
                method: 'POST',
                body: formData,
                redirect: 'follow'
            };
            fetch(url, requestOptions).then(function (response) {
                if (response.ok) {
                    this_form.reset();

                    const success_msg = document.getElementById('success-message')
                    const client_name = document.getElementById('client_name')
                    client_name.innerText = client_form_name
                    success_msg.style.display = 'flex'

                    setTimeout(function () {
                        success_msg.remove()
                        let submit = document.getElementById('formTopSubmit')
                        submit.style.display = 'none'
                    }, 10000);
                }
                return Promise.reject(response);
            }).then(function (data) {
                console.log(data);
            }).catch(function (error) {
                console.warn(error);
            });
        });
    }

    if ( document.getElementById('FormSubmit') != null ) {

        document.getElementById('FormSubmit').addEventListener('click', function (e){
            e.preventDefault();

            const this_form = document.getElementById('inContent_Form')

            const BASE_URL = "https://miraclehairturkey.com/api"
            const ROUTE_URL = '/home-contact-request'
            const url = BASE_URL + ROUTE_URL

            const phone_coded = SectionContactF.getNumber(intlTelInputUtils.numberFormat.E164);
            const countryData = SectionContactF.getSelectedCountryData();

            let country = countryData.name
            let date = new Date();
            let formName = this_form.getAttribute("name")
            let referral_page = window.location.href;
            let language = navigator.language;
            let time_zone_offset = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var form = document.querySelector('#inContent_Form');
            var formData = new FormData(form);
            formData.append('phone', phone_coded)
            formData.append('form_name', formName)
            formData.append('country', country)
            formData.append('user_language', language)
            formData.append('user_time_zone', time_zone_offset)
            formData.append('referral_page', referral_page)
            formData.append('date', date)

            let client_form_name = formData.get('name')

            var requestOptions = {
                method: 'POST',
                body: formData,
                redirect: 'follow'
            };
            fetch(url, requestOptions).then(function (response) {
                if (response.ok) {
                    this_form.reset();

                    const success_msg = document.getElementById('success-message')
                    const client_name = document.getElementById('client_name')
                    client_name.innerText = client_form_name
                    success_msg.style.display = 'flex'

                    setTimeout(function () {
                        success_msg.remove()
                        let submit = document.getElementById('formTopSubmit')
                        submit.style.display = 'none'
                    }, 10000);
                }
                return Promise.reject(response);
            }).then(function (data) {
                console.log(data);
            }).catch(function (error) {
                console.warn(error);
            });
        });
    }
}
