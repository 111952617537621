require('./lazyload')
require('./forms')
const lightbox = new FsLightbox();

document.getElementById('mobile_menu_btn')
    .addEventListener('click', () => {
        document.getElementById('mobile_menu_btn').classList.toggle('menu_open')
        document.getElementById('main').classList.toggle('overflow-hidden')
        document.getElementById('mobile-menu').classList.toggle('hidden')
        document.getElementById('mobile-menu').classList.toggle('animate__animated')
        document.getElementById('mobile-menu').classList.toggle('animate__fadeInDown')
    })

document.getElementById('mob_lang_btn')
    .addEventListener('click', ()=> {
        document.getElementById('mob_lang_list').classList.toggle('hidden')
    })

document.getElementById('lang_back_btn')
    .addEventListener('click', ()=> {
        document.getElementById('mob_lang_list').classList.toggle('hidden')
    })

document.getElementById('surgey_list_btn')
    .addEventListener('click', ()=> {
        document.getElementById('surgery_list').classList.toggle('hidden')
    })
document.getElementById('surgery_back_btn')
    .addEventListener('click', ()=> {
        document.getElementById('surgery_list').classList.toggle('hidden')
    })

function toggleDropdown(id){
    document.getElementById(id).classList.toggle('hidden')
}
/*document.getElementById('lang_switcher_btn').addEventListener('click', () => {
    toggleDropdown('locales_list')
})*/


